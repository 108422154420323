"use client";

import MinimalHeader from "components/headers/minimal-header";
import HeroBanner from "components/hero-banner";
import { ILink } from "helpers/helpers";

/* Ideally the banner will take 1x1 image and a 3x1 */
/* Some older pages don't have  a 3x1 so the 16x9 is used as a fallback */
const PageHeader = ({
  title,
  description,
  squareImage,
  wideLandscapeImage,
  landscapeImage,
  links,
  children,
  displayLanguageFilter = false,
  availableLanguages = [],
  languageDescriptions = []
}: PageHeaderProps) => {
  return <div data-sentry-component="PageHeader" data-sentry-source-file="index.tsx">
			{(wideLandscapeImage || squareImage || landscapeImage) && <HeroBanner squareImage={squareImage} wideLandscapeImage={wideLandscapeImage || landscapeImage} />}
			<MinimalHeader heading={title} description={description} links={links} displayLanguageFilter={displayLanguageFilter} availableLanguages={availableLanguages} languageDescriptions={languageDescriptions} data-sentry-element="MinimalHeader" data-sentry-source-file="index.tsx">
				{children}
			</MinimalHeader>
		</div>;
};
interface PageHeaderProps {
  children?: any;
  description?: string;
  landscapeImage?: object;
  squareImage?: object;
  title?: string;
  links?: ILink[];
  wideLandscapeImage?: object;
  displayLanguageFilter?: boolean;
  availableLanguages?: {
    language: string;
  }[];
  languageDescriptions?: {
    id: string;
    descriptions: string;
  }[];
}
export default PageHeader;